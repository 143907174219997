export const pageSlug = post => {
  switch (post.internal?.type) {
    case "ContentfulPerson":
      return `/team/${post.slug}`

    case "ContentfulInterview":
      return `/athletes/${post.slug}`

    case "ContentfulBook":
      return `/r/${post.slug}`

    case "ContentfulRecipe":
      return `/recipes/${post.slug}`

    default:
      return `/${post.slug}`
  }
}
