import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import shortcodes from "../components/shortcodes"

import { pageSlug } from "../lib/page-slug-full"
import { breakpoints } from "../parameters/theme"

const shortcodeComps = {
  ...shortcodes,
  a: shortcodes.MDXLink,
}

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    margin-top: 0;
  }
`

const AthleteTemplate = ({
  data: { contentfulInterview: interview },
  location,
}) => {
  const headerBackground = interview.backgroundImage
    ? interview.backgroundImage.fluid
    : {}

  return (
    <Layout
      location={location}
      pageSlug={pageSlug(interview)}
      pageType="athlete"
      pageClassName="post"
      pageContext={{ category: interview.category }}
      title={interview.title}
      header={interview.header}
      description={interview.excerpt ? interview.excerpt.excerpt : ""}
      headerBackground={headerBackground}
      nodeData={interview}
    >
      <MDXProvider components={shortcodeComps}>
        <Container>
          {interview.childContentfulMdx &&
          interview.childContentfulMdx.childMdx ? (
            <MDXRenderer>
              {interview.childContentfulMdx.childMdx.body}
            </MDXRenderer>
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: interview.body ? interview.body.body : "",
              }}
            ></div>
          )}
        </Container>
      </MDXProvider>
    </Layout>
  )
}

export const query = graphql`
  query GetInterviewByID($contentfulId: String) {
    contentfulInterview(contentful_id: { eq: $contentfulId }) {
      title
      header
      slug
      category
      backgroundImage {
        title
        fluid(quality: 100, maxWidth: 768) {
          ...GatsbyContentfulFluid
        }
      }
      images {
        fluid(quality: 100) {
          src
        }
      }
      excerpt {
        excerpt
      }
      childContentfulMdx {
        childMdx {
          body
        }
      }
      body {
        body
      }
      internal {
        type
      }
      createdAt(formatString: "DD MMM, Y")
      updatedAt(formatString: "DD MMM, Y")
    }
  }
`

export default AthleteTemplate
